import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { GenericPageLayout, StoryPageLayout } from '../components/layouts'

class GenericPageTemplate extends React.Component {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageGeneric')
        const storyPage = { ...page, content: page.richText };
        const layout = page.layout ? page.layout.layout : '';
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)

        let result = <></>;
        switch (layout) {
            case 'Story':
                result = <StoryPageLayout site={site} story={storyPage} sitemap={sitemap} />;
                break;
            default:
                result = <GenericPageLayout site={site} page={page} hasGroup={!!page.groups} location={this.props.location} sitemap={sitemap} />;;
                break;
        }
        return result;
    }
}

export default GenericPageTemplate

export const pageQuery = graphql`
query GenericPageBySlug($slug: String!) {
    site {
        siteMetadata {
            title
            description
            siteUrl
            twitterHandle
        }
    }
    contentfulPageGeneric(slug: { eq: $slug }) {
        __typename
        title
        slug
        userSection
        summary {
            summary
        }
        groups {
            slug
            title
            contentful_id
            userSection
        }
        richText {
            childContentfulRichText {
              internal {
                content
              }
              html
            }
        }
        layout {
            layout
        }
        pageDonation {
            slug
        }
        storyBlock {
            contentful_id
            title
            summary {
                summary   
            }
            backgroundImage {
                title
                file {
                    url
                }
            }
            donateAmount
            story {
                __typename
                slug
            }
            recurring
            donateReason
            textButton
            externalHyperlink
            internalHyperlink {
                __typename
                ... on ContentfulPageGeneric {
                    title
                    slug
                    userSection
                    layout {
                        layout
                    }
                }
                ... on ContentfulPageGuideline {
                    title
                    slug
                }
                ... on ContentfulPageWidgets {
                    title
                    slug
                    userSection
                }
                ... on ContentfulPageGroup {
                    title
                    slug
                    userSection
                }
            }
        }
        heroImage {
            title
            file {
                url
                details {
                    size
                }
            }
        }
        heroVideo
        heroImageFullWidth
        showSocialShare
        metaDescription {
            metaDescription
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`
